import { roleAdmin, roleSuperAdmin, roleManager, roleOperator  } from '@/mixins/roles'

const Holidays = () => import('@/pages/holidays.vue');

export default [
  {
    path: 'holidays',
    name: 'Holidays',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator ],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Праздники',
    },
    component: Holidays,
  },
  {
    path: 'holidays/:id',
    name: 'HolidaysEdit',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager, roleOperator ],
        forbiddenRedirect: '/forbidden',
      },
      title: 'Праздники',
    },
    component: Holidays,
  },
];
