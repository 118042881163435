const languages = {
  ru: {
    label: 'Русский',
    dir: 'ltr',
    rules: 'required',
  },
  en: {
    label: 'English',
    dir: 'ltr',
  },
  de: {
    label: 'Deutsch',
    dir: 'ltr',
  },
  es: {
    label: 'Español',
    dir: 'ltr',
  },
  fr: {
    label: 'Français',
    dir: 'ltr',
  },
  he: {
    label: 'עברית',
    dir: 'rtl',
  },
};

const sidebarLinks = [
  {
    name: 'Экскурсии',
    children: [
      {
        name: 'Заказы',
        icon: 'icomoon icon-orders',
        path: '/orders',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Экскурсии',
        icon: 'icomoon icon-excursions',
        path: '/excursions',
        roles: ['manager', 'editor'],
      },
      {
        name: 'Трансферы',
        icon: 'icomoon icon-Transfers',
        path: '/transfers',
        roles: ['manager', 'editor'],
      },
      {
        name: 'Достоприм-ности',
        icon: 'icomoon icon-attractions',
        path: '/attractions',
        roles: ['manager', 'editor'],
      },
      {
        name: 'Точки стыковки',
        icon: 'icomoon icon-point_transit',
        path: '/transit-points',
        roles: ['manager'],
      },
      {
        name: 'Точки сбора',
        icon: 'icomoon icon-collection_points',
        path: '/collection-points',
        roles: ['manager'],
      },
      {
        name: 'Регионы',
        icon: 'icomoon icon-regions',
        path: '/regions',
        roles: ['manager'],
      },
      {
        name: 'Направления',
        icon: 'icomoon icon-directions',
        path: '/excursion-directions',
        roles: ['manager'],
      },
      {
        name: 'Типы экскурсий',
        icon: 'icomoon icon-ex_types',
        path: '/excursion-types',
        roles: ['manager'],
      },
      {
        name: 'Города',
        icon: 'icomoon icon-cities',
        path: '/cities',
        roles: ['manager', 'editor'],
      },
    ],
  },
  {
    name: 'Экскурсии мир',
    children: [
      {
        name: 'Заказы',
        icon: 'icomoon icon-orders',
        path: '/world-tours/orders',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Список заявок',
        icon: 'icomoon icon-orders',
        path: '/world-tours/requests',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Экскурсии',
        icon: 'icomoon icon-excursions',
        path: '/world-tours/excursions',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Достоприм-ности',
        icon: 'icomoon icon-attractions',
        path: '/world-tours/attractions',
        roles: ['manager'],
      },
      {
        name: 'Разделы',
        icon: 'icomoon icon-sections',
        path: '/world-tours/sections',
        roles: ['manager'],
      },
      {
        name: 'Гиды',
        icon: 'icomoon icon-guides',
        path: '/world-tours/guides',
        roles: ['manager'],
      },
      {
        name: 'Страны',
        icon: 'icomoon icon-countries',
        path: '/world-tours/countries',
        roles: ['manager'],
      },
      {
        name: 'Города',
        icon: 'icomoon icon-countries',
        path: '/world-tours/cities',
        roles: ['manager'],
      },
      {
        name: 'Типы передвижения',
        icon: 'icomoon icon-movement-types',
        path: '/world-tours/movement-types',
        roles: ['manager'],
      },
      {
        name: 'Категории',
        icon: 'icomoon icon-categories',
        path: '/world-tours/categories',
        roles: ['manager'],
      },
      {
        name: 'Классы',
        icon: 'icomoon icon-classes',
        path: '/world-tours/category-types',
        roles: ['manager'],
      },
      {
        name: 'Типы цен',
        icon: 'icomoon icon-price_types',
        path: '/world-tours/price-types',
        roles: ['manager'],
      },
    ],
  },
  {
    name: 'Организованные туры',
    children: [
      {
        name: 'Заказы',
        icon: 'icomoon icon-orders',
        path: '/organisation-tours/orders',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Список заявок',
        icon: 'icomoon icon-orders',
        path: '/world-tours/requests',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Туры',
        icon: 'icomoon icon-excursions',
        path: '/organisation-tours/tours',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Достоприм-ности',
        icon: 'icomoon icon-attractions',
        path: '/organisation-tours/attractions',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Страны',
        icon: 'icomoon icon-countries',
        path: '/organisation-tours/countries',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Направления',
        icon: 'icomoon icon-directions',
        path: '/tours-directions',
        roles: ['manager'],
      },
      {
        name: 'Праздники',
        icon: 'icomoon icon-holidays',
        path: '/holidays',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Гиды',
        icon: 'icomoon icon-guides',
        path: '/organisation-tours/guides',
        roles: ['manager'],
      },
      {
        name: 'Типы туров',
        icon: 'icomoon icon-categories',
        path: '/organisation-tours/tourTypes',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Типы цен',
        icon: 'icomoon icon-price_types',
        path: '/world-tours/price-types',
        roles: ['manager'],
      },
      {
        name: 'Тур операторы',
        icon: 'icomoon icon-price_types',
        path: '/organisation-tours/tourOperators',
        roles: ['manager', 'operator'],
      },
    ],
  },

  {
    name: 'SPA',
    children: [
      {
        name: 'Заказы',
        icon: 'icomoon icon-orders',
        path: '/hotels/orders',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Отели',
        icon: 'icomoon icon-hotels',
        path: '/hotels/hotels',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Типы отдыха',
        icon: 'icomoon icon-rest-types',
        path: '/hotels/rest-types',
        roles: ['manager'],
      },
      {
        name: 'Питание',
        icon: 'icomoon icon-food-types',
        path: '/hotels/food-types',
        roles: ['manager'],
      },
      {
        name: 'Звездность',
        icon: 'icomoon icon-stars',
        path: '/hotels/stars',
        roles: ['manager'],
      },
      {
        name: 'Параметры',
        icon: 'icomoon icon-parameters',
        path: '/hotels/parameters',
        roles: ['manager'],
      },
      {
        name: 'Процедуры',
        icon: 'icomoon icon-procedures',
        path: '/hotels/procedures',
        roles: ['manager'],
      },
      {
        name: 'Страны',
        icon: 'icomoon icon-countries',
        path: '/hotels/countries',
        roles: ['manager'],
      },
      {
        name: 'Города',
        icon: 'icomoon icon-cities',
        path: '/hotels/cities',
        roles: ['manager'],
      },
    ],
  },
  {
    name: 'Клиенты',
    children: [
      {
        name: 'Агенты',
        icon: 'icomoon icon-agents',
        path: '/agents',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Агентства',
        icon: 'icomoon icon-agencies',
        path: '/agencies',
        roles: ['manager', 'operator', 'holiday'],
      },
      {
        name: 'Клиенты',
        icon: 'icomoon icon-agents',
        path: '/clients',
        roles: ['manager'],
      },
      {
        name: 'Уровни',
        icon: 'icomoon icon-levels',
        path: '/levels',
        roles: ['manager'],
      },
      {
        name: 'Пакеты',
        icon: 'icomoon icon-packages',
        path: '/packages',
        roles: ['manager', 'operator'],
      },
      {
        name: 'Сотрудничество',
        icon: 'icomoon icon-partners',
        path: '/partnership',
        roles: ['manager'],
      },
      {
        name: 'Подписчики',
        icon: 'icomoon icon-podpischiki',
        path: '/subscribers',
        roles: ['manager'],
      },
    ],
  },
  {
    name: 'Контент',
    children: [
      {
        name: 'Офисы',
        icon: 'icomoon icon-office',
        path: '/offices',
        roles: ['editor'],
      },
      {
        name: 'Мероприятия',
        icon: 'icomoon icon-events',
        path: '/events',
        roles: ['editor'],
      },
      {
        name: 'Отзывы',
        icon: 'icomoon icon-reviews_icon',
        path: '/feedback',
        roles: ['editor'],
      },
      /* {
        name: 'Подразделения',
        icon: 'icomoon icon-team_icon',
        path: '/team/categories',
        roles: [''],
      }, */
      {
        name: 'Команда',
        icon: 'icomoon icon-team_icon',
        path: '/team',
        roles: ['editor'],
      },
      {
        name: 'Статика',
        icon: 'icomoon icon-page_icon',
        path: '/pages',
        roles: ['editor'],
      },
      {
        name: 'SEO',
        icon: 'icomoon icon-page_icon',
        path: '/seo',
        roles: ['editor'],
      },
      {
        name: 'Redirects',
        icon: 'icomoon icon-page_icon',
        path: '/redirects',
        roles: ['editor'],
      },
      {
        name: 'Контакты',
        icon: 'icomoon icon-mail',
        path: '/contacts',
        roles: ['editor'],
      },
    ],
  },
  {
    name: 'Блог',
    children: [
      {
        name: 'Статьи',
        icon: 'icomoon icon-blog-articles',
        path: '/blog/articles',
        roles: ['editor'],
      },
      {
        name: 'Категории',
        icon: 'icomoon icon-blog-categories',
        path: '/blog/categories',
        roles: ['editor'],
      },
      {
        name: 'Авторы',
        icon: 'icomoon icon-blog-authors',
        path: '/blog/authors',
        roles: ['editor'],
      },
    ],
  },
  {
    name: 'Другое',
    children: [
      {
        name: 'Валюты',
        icon: 'icomoon icon-currency',
        path: '/currencies',
        roles: ['manager'],
      },
      {
        name: 'Типы цен',
        icon: 'icomoon icon-price_types',
        path: '/price-types',
        roles: ['manager'],
      },
      {
        name: 'Типы точек',
        icon: 'icomoon icon-pc_categories',
        path: '/pc-categories',
        roles: ['manager'],
      },
      {
        name: 'Классы',
        icon: 'icomoon icon-classes',
        path: '/excursion-categories-types',
        roles: ['manager'],
      },
      {
        name: 'Категории',
        icon: 'icomoon icon-categories',
        path: '/excursion-categories',
        roles: ['manager'],
      },
      {
        name: 'Пользователи',
        icon: 'icomoon icon-managers',
        path: '/managers',
        roles: ['superAdmin'],
      },
      {
        name: 'Уведомления',
        icon: 'icomoon icon-notification',
        path: '/notifications',
        roles: ['manager'],
      },
      {
        name: 'Настройки',
        icon: 'icomoon icon-ex_types',
        path: '/settings',
        roles: ['manager'],
      },
    ],
  },
  {
    name: 'Logs',
    path: '/logs',
    roles: ['admin', 'superAdmin'],
  },
  // {
  //   name: 'holiday',
  //   children: [
  //     {
  //       name: 'Страны',
  //       icon: 'icomoon icon-excursions',
  //       path: '/holiday/countries',
  //       roles: ['manager', 'holiday']
  //     },
  //     {
  //       name: 'Города',
  //       icon: 'icomoon icon-cities',
  //       path: '/holiday/cities',
  //       roles: ['manager', 'holiday']
  //     },
  //     {
  //       name: 'Регионы',
  //       icon: 'icomoon icon-regions',
  //       path: '/holiday/regions',
  //       roles: ['manager', 'holiday']
  //     },
  //     {
  //       name: 'Курорты',
  //       roles: ['holiday'],
  //       children: [
  //         {
  //           name: 'Список Курортов',
  //           icon: 'icomoon icon-attractions',
  //           path: '/holiday/resorts-list',
  //           roles: ['manager']
  //         },
  //         {
  //           name: 'Показания',
  //           icon: 'icomoon icon-levels',
  //           path: '/holiday/readings',
  //           roles: ['manager']
  //         },
  //         {
  //           name: 'Противопоказания',
  //           icon: 'icomoon icon-agencies',
  //           path: '/holiday/contraindications',
  //           roles: ['manager']
  //         },
  //         {
  //           name: 'Осн. методы лечения',
  //           icon: 'icomoon icon-point_transit',
  //           path: '/holiday/treatments',
  //           roles: ['manager']
  //         }
  //       ]
  //     },
  //     {
  //       name: 'Отели',
  //       roles: ['holiday'],
  //       children: [
  //         {
  //           name: 'Список Отелей',
  //           icon: 'icomoon icon-attractions',
  //           path: '/holiday/hotels',
  //           roles: ['manager']
  //         },
  //         {
  //           name: 'Что есть в отеле',
  //           icon: 'icomoon icon-levels',
  //           path: '/holiday/items-hotel',
  //           roles: ['manager']
  //         },
  //         {
  //           name: 'Питание',
  //           icon: 'icomoon icon-agencies',
  //           path: '/holiday/food-hotel',
  //           roles: ['manager']
  //         },
  //         {
  //           name: 'Типы',
  //           icon: 'icomoon icon-point_transit',
  //           path: '/holiday/types-hotel',
  //           roles: ['manager']
  //         },
  //         {
  //           name: 'Дополнительные стоимости',
  //           icon: 'icomoon icon-point_transit',
  //           path: '/holiday/additionally-costs-hotel',
  //           roles: ['manager']
  //         }
  //       ]
  //     },
  //   ]
  // },
];

export { sidebarLinks as default, sidebarLinks, languages };
